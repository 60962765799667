import React, { useState } from "react"
import styled from "styled-components"

import ButtonLink from "~components/ButtonLink"
import Hidden from "~components/Hidden"
import CertificatesImage from "~images/logos2.png"
import CertificatesImageMobile from "~images/logos-mobile2.png"
import Col from "~layout/Col"
// import Box from "@material-ui/core/Box"
import Container from "~layout/Container"
import Row from "~layout/Row"
const DetailTitle = styled.div`
  color: ${props => (props.active ? "white" : "#6FC2B4")};
  background-color: ${props => (props.active ? "#6FC2B4" : "white")};
  ${props =>
    props.active
      ? `
    box-shadow: 0px 2px 15px rgba(0,0,0,0.3);
  `
      : null};
  @media ${({ theme }) => theme.breakpoint.md} {
    ${props =>
      props.active
        ? `
    box-shadow: 0px 2px 26px rgba(0,0,0,0.5);
  `
        : null};
  }
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  box-sizing: border-box;
  flex-shrink: 0;
  text-decoration: none;
  display: inline-block;
  border: 2px solid #6fc2b4;
  text-align: center;
  border-top-right-radius: 22px;
  border-bottom-left-radius: 22px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  cursor: pointer;
  @media ${({ theme }) => theme.breakpoint.md} {
    text-align: left;
  }
  @media (max-width: 1280px) {
    font-size: 0.9rem;
    min-height: 3.5rem;
    line-height: 1.5em;
  }
  @media (max-width: 767px) {
  }
`

const TitleLine = styled.div`
  height: 2px;
  width: 50px;
  background-color: #6fc2b4;
  @media (max-width: 1280px) {
    width: 5px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`

const Summary = styled.div`
  margin-top: 32px;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }
  color: #003d6a;
`

const SectionTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 22px;
    font-weight: 600;
    line-height: 20px;
  }
  color: #6fc2b4;
`

const SectionContent = styled.div`
  margin-top: 10px;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-top: 32px;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }
  color: #003d6a;
`

const RowWrapper = styled(Row)`
  @media ${({ theme }) => theme.breakpointDown.md} {
    /* width: 100%; */
    /* width: 600px; */
    overflow-x: auto;
    /* height: 61px; */
    position: relative;
    margin-left: 0px;
    margin-right: 0px;
    flex-wrap: nowrap;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`

const WrapperCol = styled(Col)`
  @media ${({ theme }) => theme.breakpoint.md} {
    flex: 0 0 16.666%;
    max-width: 16.666%;
    padding-left: 13.5px;
    padding-right: 13.5px;
  }
  @media ${({ theme }) => theme.breakpointDown.md} {
    &:last-child {
      ${DetailTitle} {
        &:after {
          display: none;
        }
      }
    }
  }

  &.section0 {
    /* padding-right: 0; */
  }
  &.section1 {
    /* padding-left: 0; */
    /* padding-right: 0; */
  }
  &.section2 {
    /* padding-left: 0; */
    /* padding-right: 0; */
  }
  @media ${({ theme }) => theme.breakpointDown.md} {
    &.section0,
    &.section1,
    &.section2,
    &.section3,
    &.section4 {
      flex: initial;
      width: 150px;
      min-width: 150px;
      max-width: initial !important;
      /* padding-left: 0; */
      /* padding-right: 0; */
    }
  }
`
const Wrapper = styled.div`
  margin-top: 44px;
  margin-bottom: 68px;
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-top: 100px;
    margin-bottom: 30px;
  }
  @media ${({ theme }) => theme.breakpointDown.md} {
    overflow: hidden;
  }
`

const Tab = styled.div`
  max-width: 422px;
  margin: 0 auto;
  ${DetailTitle} {
    padding-left: 10px;
  }
  & > div,
  ${DetailTitle} {
    display: flex;
    justify-content: left;
    align-items: center;
  }
  /* align-items: center; */
  ${DetailTitle} {
    height: 61px;
    &:after {
      position: absolute;
      display: block;
      content: "";
      width: 50vw;
      height: 2px;
      background: #6fc2b4;
      z-index: -1;
      left: 20px;
    }
  }
  @media ${({ theme }) => theme.breakpointDown.lg} {
    @media ${({ theme }) => theme.breakpoint.md} {
      ${DetailTitle} {
        font-size: 14px;
        padding-left: 10px;
        padding-right: 6px;
      }
    }
  }
`

const StyledRowSus = styled(Row)`
  margin-bottom: 20px;

  &.row3 ${Summary} p:nth-child(3) {
    font-weight: bold;
  }
  span.heading {
    color: #6fc2b4;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
  }
  span.boldParagraph {
    font-weight: bold;
  }
  @media ${({ theme }) => theme.breakpoint.md} {
    &.row4 {
      span.heading {
        color: #6fc2b4;
        font-size: 22px;
        font-weight: 600;
        line-height: 20px;
      }
      span.boldParagraph {
        font-weight: bold;
      }
    }
    margin-bottom: 50px;
    &.row5 > div > div,
    &.row4 > div > div,
    &.row2 > div > div {
      column-count: 2;
    }
  }
  &.row5 > div,
  &.row4 > div,
  &.row3 > div,
  &.row2 > div {
    width: 100%;
    max-width: 100%;
    flex: 100%;
  }
  @media ${({ theme }) => theme.breakpoint.xl} {
    &.row4 ${Summary} {
      
    }
  }
`

const CertRow = styled(Row)`
  & > div {
    margin-top: 20px;
  }
`

const StyledContainer = styled(Container)`
  overflow: hidden;
  @media ${({ theme }) => theme.breakpoint.md} {
    overflow: visible;
  }
`

const StyledReport = styled(ButtonLink)`
  float: left;
  line-height: 16px;
  & *:after {
    line-height: 1em;
  }
  & *:before {
    line-height: 1em;
  }
`

const StyledCertificate = styled(ButtonLink)`
  & div {
    line-height: 20px;
  }
  & *,
  & {
    width: 100%;
    text-align: left;
  }

  @media ${({ theme }) => theme.breakpoint.md} {
    @media ${({ theme }) => theme.breakpointDown.xl} {
      height: 100%;
      min-height: 60px;
      * {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }
`

const Source = styled.div`
  span {
    font-weight: bold;
  }
  /* float: right; */
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto;
  margin-top: 30px;
  color: #003d6a;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  text-align: center;
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }
`
const StyledCertificatesImage = styled.img`
  margin-bottom: 0;
  display: block;
  width: 90%;
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto;
  margin-top: 50px;
  @media ${({ theme }) => theme.breakpointDown.xl} {
    width: 100%;
  }
`
const SustainabilityDetails = ({ details, esgReport, praderaNetZeroCarbonPathway, certificates }) => {
  const [activeDetailIndex, setActiveDetailIndex] = useState(0)
  return (
    <StyledContainer>
      <Wrapper>
        <RowWrapper>
          {details &&
            details.map((detail, index) => {
              return (
                <WrapperCol className={"section" + index} xs={2} md={2} xl={2}>
                  <Tab>
                    <div flexGrow={1}>
                      <DetailTitle
                        onClick={() => setActiveDetailIndex(index)}
                        active={index === activeDetailIndex}
                      >
                        {detail.title}
                      </DetailTitle>
                    </div>
                  </Tab>
                </WrapperCol>
              )
            })}
        </RowWrapper>
        {details && details.length > activeDetailIndex && (
          <StyledRowSus className={"row" + activeDetailIndex}>
            <Col xs={12} md={8} xl={8}>
              <Summary>
                <div
                  dangerouslySetInnerHTML={{
                    __html: details[activeDetailIndex].summary,
                  }}
                />
                {activeDetailIndex === 1 && (
                  <StyledReport
                    to={praderaNetZeroCarbonPathway?.mediaItemUrl}
                    title="Pradera Net Zero Carbon Pathway"
                    color="#EE751A"
                    borderColor="#EE751A"
                    initialText="Pradera Net Zero Carbon Pathway"
                    changedText="Download"
                    target="_blank"
                  />
                )}
                {activeDetailIndex === 2 && (
                  <StyledReport
                    to={esgReport.mediaItemUrl}
                    title="Download the Pradera ESG Report"
                    color="#EE751A"
                    borderColor="#EE751A"
                    initialText="Download the Pradera ESG Report"
                    changedText="Download"
                    target="_blank"
                  />
                )}
              </Summary>
              <CertRow>
                {activeDetailIndex === 3 &&
                  certificates.map(item => (
                    <Col md={4}>
                      <StyledCertificate
                        to={item.certificate.mediaItemUrl}
                        title={item.certificate.name}
                        color="#EE751A"
                        borderColor="#EE751A"
                        initialText={item.name}
                        changedText="Download"
                        target="_blank"
                      />
                    </Col>
                  ))}
              </CertRow>
              {activeDetailIndex === 3 && (
                <Row>
                  <Col>
                    <Hidden mdDown>
                      <StyledCertificatesImage
                        src={CertificatesImage}
                        alt="Our certificates"
                      />
                    </Hidden>
                    <Hidden lgUp>
                      <StyledCertificatesImage
                        src={CertificatesImageMobile}
                        alt="Our certificates"
                      />
                    </Hidden>
                  </Col>
                  <Col>
                    <Source>
                      <span>Source:</span> Based on total floor area of
                      certified assets
                    </Source>
                  </Col>
                </Row>
              )}
            </Col>
          </StyledRowSus>
        )}
        <Row>
          {details &&
            details.length > activeDetailIndex &&
            details[activeDetailIndex].sections &&
            details[activeDetailIndex].sections.map(section => (
              <Col xs={12} md={4} xl={4}>
                <SectionTitle>{section.title}</SectionTitle>
                <SectionContent
                  dangerouslySetInnerHTML={{ __html: section.content }}
                />
              </Col>
            ))}
        </Row>
      </Wrapper>
    </StyledContainer>
  )
}

export default SustainabilityDetails
