import React from "react"
import styled from "styled-components"

// import { Container, Grid, Hidden } from "@material-ui/core"
import Hidden from "~components/Hidden"
import Col from "~layout/Col"
import Container from "~layout/Container"
import Row from "~layout/Row"

const HeroWrapper = styled.div`
  position: relative;
  color: white;
`
const HeroImg = styled.img`
  position: relative;
  display: block;
  max-height: 538px;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  z-index: -1;
  border-top-right-radius: 22px;
  border-bottom-left-radius: 22px;
`

const MB65 = styled.div`
  margin-bottom: 65px;
`

const LinearGradient = styled.div`
  width: 100%;
  background-image: linear-gradient(
    to left,
    rgb(0, 0, 0, 0),
    rgb(0, 0, 0, 0.77)
  );
  max-height: 538px;
  margin-bottom: 80px;
  border-top-right-radius: 22px;
  border-bottom-left-radius: 22px;
`
const HeroText = styled.div`
  position: absolute;
  font-family: "Palanquin", sans-serif;
  text-rendering: optimizeLegibility;
  top: 77px;
  margin-left: 112px;
  margin-right: 112px;
`
const HeroTitle = styled.div`
  font-weight: 400;
  font-size: 2.5rem;
  line-height: 1;
  margin-bottom: 16px;
  max-width: 80%;
  @media (max-width: 1024px) {
    font-size: 1.5rem;
  }
`

const MobileTitle = styled.div`
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  color: #6fc2b4;
`

const Content = styled.div`
  margin-top: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: white;
  @media ${({ theme }) => theme.breakpointDown.md} {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #003d6a;
  }
  &.contentLeft {
    font-weight: 700;
  }

  &.contentRight {
    margin-bottom: 55px;
    @media ${({ theme }) => theme.breakpoint.md} {
      margin-bottom: 40px;
    }
  }
`

const SustainabilityAward = ({ image, title, contentSections }) => {
  const contentLeft =
    contentSections && contentSections.length > 0
      ? contentSections[0].content
      : ""
  const contentRight =
    contentSections && contentSections.length > 1
      ? contentSections[1].content
      : ""
  return (
    <></>
    // <Container>
    //   <Hidden xsDown>
    //     <HeroWrapper>
    //       <HeroText>
    //         {title && <HeroTitle>{title}</HeroTitle>}
    //         <Row>
    //           <Col xs={6}>
    //             <Content
    //               className="contentLeft"
    //               dangerouslySetInnerHTML={{ __html: contentLeft }}
    //             />
    //           </Col>
    //           <Col xs={6}>
    //             <Content
    //               className="contentRight"
    //               dangerouslySetInnerHTML={{ __html: contentRight }}
    //             />
    //           </Col>
    //         </Row>
    //       </HeroText>
    //       <LinearGradient>
    //         <HeroImg src={image.sourceUrl} alt={image.alt} />
    //       </LinearGradient>
    //     </HeroWrapper>
    //   </Hidden>
    //   <Hidden mdUp>
    //     <HeroImg src={image.sourceUrl} alt={image.alt} />
    //     <h3>
    //       <MobileTitle>{title}</MobileTitle>
    //     </h3>
    //     <Content
    //       className="contentLeft"
    //       dangerouslySetInnerHTML={{ __html: contentLeft }}
    //     />
    //     <Content
    //       className="contentRight"
    //       dangerouslySetInnerHTML={{ __html: contentRight }}
    //     />
    //   </Hidden>
    // </Container>
  )
}

export default SustainabilityAward
