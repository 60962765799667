import React from "react"
import styled from "styled-components"
import Container from "~layout/Container"
import StaticHero from "../../components/StaticHero"

const Wrapper = styled.div`
  width: 100%;
`

const SustainabilityHero = ({ image, title, subtitle }) => {
  return (
    <Container maxWidth={false} disableGutters={true}>
      <Wrapper>
        <StaticHero image={image} title={title} subtitle={subtitle} />
      </Wrapper>
    </Container>
  )
}

export default SustainabilityHero
