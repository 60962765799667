import React from "react"
import styled from "styled-components"

import Hidden from "~components/Hidden"
import Col from "~layout/Col"
import Container from "~layout/Container"
import Row from "~layout/Row"

const Wrapper = styled.div`
  margin-top: 50px;
  width: 100%;
  @media (max-width: 1280px) {
    margin-top: 0px;
  }
`

const ValueWrapper = styled.div`
  margin-top: 20px;
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-top: 32px;
  }
`

const Title = styled.div`
  font-size: 25px;
  font-weight: 500;
  line-height: 20px;
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 22px;
    font-weight: 600;
    line-height: 20px;
  }
  color: #6fc2b4;
  margin-bottom: 16px;
`

const Content = styled.div`
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
  }
  color: #003d6a;
`

const ValueImgBelow = styled.img`
  position: relative;
  display: block;
  object-fit: cover;
  object-position: center center;
  width: 100%;

  @media ${({ theme }) => theme.breakpoint.md} {
    min-height: 240px;
    max-width: 450px;
  }
`
const ValueImg = styled.img`
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.26);
  position: relative;
  display: block;
  object-fit: cover;
  object-position: center center;
  width: 100%;

  @media ${({ theme }) => theme.breakpoint.md} {
    min-height: 240px;
  }
  border-top-right-radius: 22px;
  border-bottom-left-radius: 22px;
`
const StyledCol = styled(Col)`
  order: ${props => props.order};
  @media ${({ theme }) => theme.breakpointDown.md} {
    &.imageSide {
      order: 2;
    }
  }
`

const Inheritance = styled.div`
  &.section0 {
    img {
      box-shadow: none;
      object-fit: contain;
    }
    ${StyledCol} {
      padding-right: 40px;
    }
  }
  ${StyledCol} {
    padding-left: 40px;
  }

  &.section1 {
    ${StyledCol} {
      padding-left: 40px;
    }
  }

  &.section2 {
    ${StyledCol} {
      padding-right: 40px;
    }
  }
  @media ${({ theme }) => theme.breakpoint.md} {
    all: inherit;
    margin-top: 50px;
  }

  @media ${({ theme }) => theme.breakpointDown.md} {
    & > div {
      max-width: 100%;
    }
  }
`

const StyledContainer = styled(Container)``

const ContentBlock = ({ value }) => (
  // <Col xs={12} sm={7} md={7} xl={7} order={2}>
  <StyledCol xs={12} sm={7} md={6} xl={6}>
    <Title>{value.title}</Title>
    <Content dangerouslySetInnerHTML={{ __html: value.content }} />
    {value?.imagebelowcontent?.sourceUrl && (
      <ValueImgBelow
        src={value.imagebelowcontent.sourceUrl}
        alt={value.imagebelowcontent.alt}
      />
    )}
  </StyledCol>
)

const ImageBlock = ({ value }) => (
  // <Col xs={12} sm={5} md={5} xl={5} order={1}>
  <StyledCol className="imageSide" xs={12} sm={5} md={4} xl={4}>
    <ValueImg src={value.image.sourceUrl} alt={value.image.alt} />
  </StyledCol>
)

const SustainabilityValues = ({ values }) => {
  return (
    <StyledContainer>
      <Wrapper>
        {values &&
          values.map((value, index) => (
            <ValueWrapper>
              <Row style={{ justifyContent: "center" }}>
                <Hidden xsDown>
                  {index % 2 === 0 && (
                    <Inheritance className={"section" + index}>
                      <ContentBlock value={value} />
                      <ImageBlock value={value} />
                    </Inheritance>
                  )}
                  {index % 2 !== 0 && (
                    <Inheritance className={"section" + index}>
                      <ImageBlock value={value} />
                      <ContentBlock value={value} />
                    </Inheritance>
                  )}
                </Hidden>
                <Hidden smUp>
                  <ImageBlock value={value} />
                  <ContentBlock value={value} />
                </Hidden>
              </Row>
            </ValueWrapper>
          ))}
      </Wrapper>
    </StyledContainer>
  )
}

export default SustainabilityValues
